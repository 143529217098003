import styled from '@emotion/styled';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';
import type { ZolaTheme } from '../../../ZolaThemeProvider';

const borderStyling = (theme: ZolaTheme): string => `1px solid ${theme.COLORS_GRAYS.BLACK_030}`;

export const Container = styled.div`
  ${({ theme }) => `
    background-color: ${theme.COLORS3.WHITE_100};
    margin: 60px auto 0;
    max-width: ${theme.SCREEN_XL}px;
    padding: 0 ${theme.SPACING.LG} ${theme.SPACING.LG};

    ${theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
      padding: ${theme.SPACING.S16} ${theme.SPACING.MD} ${theme.SPACING.MD};
    }

    ${theme.MEDIA_QUERY.DESKTOP_XL} {
      border-left: ${borderStyling(theme)};
      border-right: ${borderStyling(theme)};
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => `
    margin: 0 auto;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${ZINDEX.top};

    ${theme.MEDIA_QUERY.DESKTOP_XL} {
      border-left: ${borderStyling(theme)};
      border-right: ${borderStyling(theme)};
      left: calc((100% - ${theme.SCREEN_XL}px) / 2);
      width: ${theme.SCREEN_XL}px;
    }
  `}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => `
    margin: 0 auto;
    max-width: ${theme.SCREEN_XL}px;

    ${theme.MEDIA_QUERY.DESKTOP_XL} {
      border-left: ${borderStyling(theme)};
      border-right: ${borderStyling(theme)};
      border-bottom: ${borderStyling(theme)};
    }
  `}
`;

export const FiltersContainer = styled.div``;
export const ProductGridContainer = styled.div``;
