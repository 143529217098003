import React from 'react';
import type { WRegistryView } from '@zola/svc-web-api-ts-client';
import Head from 'next/head';

interface SeoTagsProps {
  registry: WRegistryView;
}

const SeoTags = (props: SeoTagsProps): JSX.Element => {
  const { registry } = props;
  const {
    registrant_first_name: registrantFirstName,
    registrant_last_name: registrantLastName,
    partner_registrant_first_name: partnerFirstName,
    partner_registrant_last_name: partnerLastName,
    greeting: greetingMessage,
    profile_image: profileImg,
    searchable_on_web: searchableOnWeb,
  } = registry || {};
  const coupleNames = `${registrantFirstName} ${registrantLastName} and ${partnerFirstName} ${partnerLastName}`;
  const metaDescription =
    greetingMessage ||
    `Check out ${coupleNames}'s Wedding Registry on Zola. Browse their gift selections now!`;
  const defaultMetaTitle = `${coupleNames}'s Wedding Registry on Zola`;
  const registryUrl = `https://www.zola.com/registry/${registry.key}`;
  return (
    <Head>
      <title key="title">{defaultMetaTitle}</title>

      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={registryUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={defaultMetaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={profileImg} />
      <meta property="og:site_name" content="Zola" />
      <meta property="og:url" content={registryUrl} />
      <meta name="twitter:site" content="@zola" />
      <meta name="twitter:card" content="summary" />
      {!searchableOnWeb && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default SeoTags;
